import React, { useCallback } from "react";
import styled from "styled-components";
import themeTransition from "styles/theme-transition";
import color from "color";
import shadowedButton from "styles/shadowed-button";

type ImagesLoaderProps = {
    label: string;
    fileName?: string;

    onChange: (fileName: string, file: ArrayBuffer) => void;

    className?: string;
};

export const ImagesLoader: React.FunctionComponent<ImagesLoaderProps> = props => {
    const clearFileInput = useCallback((target: EventTarget & HTMLInputElement) => {
        target.value = "";
    }, []);

    const onLoadFile = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files) {
                return;
            }
            const file = e.target.files[0];

            if (!file) {
                return;
            }

            const reader = new FileReader();

            reader.onload = e => {
                if (!e.target) {
                    return;
                }

                const content = reader.result as ArrayBuffer;
                props.onChange(file.name, content);
            };

            reader.readAsArrayBuffer(file);
            clearFileInput(e.target);
        },
        [props, clearFileInput],
    );

    return (
        <div className={props.className}>
            <HiddenFileInput type="file" id="file-input" onChange={onLoadFile} accept="image/*" />
            <label htmlFor="file-input">
                <LogoUploadButton>{props.label}</LogoUploadButton>
            </label>
        </div>
    );
};

const HiddenFileInput = styled.input`
    display: none;
`;

const LogoUploadButton = styled.div`
    flex: 1 1 0;
    border: none;
    padding: 1rem 1.5rem;
    color: white;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: all ${themeTransition()};

    ${p => shadowedButton({ color: p.theme.palette.orange })};

    :hover {
        background-color: ${p =>
            color(p.theme.palette.orange)
                .darken(0.2)
                .toString()};
    }
`;
