/* eslint-disable */
import { CQRS } from "@leancode/cqrs-client/CQRS";
import { ClientType } from "@leancode/cqrs-client/ClientType";

export const globals: typeof Api = {
    ApplicationPageCategoryDTO: {
        HowItWorks: 0,
        Problems: 1,
    },
    Cache: {
        For5Minutes: 300,
        For15Minutes: 900,
        For30Minutes: 1800,
        For60Minutes: 3600,
    },
    ContestEditionPageCategoryDTO: {
        Prizes: 1000,
    },
    ContestLandingGenderDTO: {
        Man: 0,
        Woman: 1,
    },
    ContestLandingSectionsDTO: {
        Eco: 0,
        Charity: 1,
    },
    ContestPageCategoryDTO: {
        Rules: 100,
        Prizes: 1000,
    },
    ContestStatusDTO: {
        New: 0,
        Active: 1,
        Finished: 2,
    },
    ContestTypeDTO: {
        Employer: 0,
        Open: 1,
    },
    CreateTeamPurchase: {
        ErrorCodes: {
            NoTeamName: 1,
            TeamNameTooLong: 2,
            NoEmail: 3,
            EmailTooLong: 4,
            NoName: 5,
            NameTooLong: 6,
            NoHeadquarterName: 7,
            HeadquarterNameTooLong: 8,
            NoCompanyName: 10,
            CompanyNameTooLong: 11,
            NoVATNumber: 12,
            VATNumberTooLong: 13,
            NoAddress: 14,
            AddressTooLong: 15,
            NoZipCode: 16,
            ZipCodeTooLong: 17,
            PurchaseExists: 100,
            ContestDoesNotExist: 101,
            ContestDoesNotAllowPremiumTeam: 102,
        },
    },
    HeadquarterBonusDTO: {
        AwayFromHeadquarter: 0,
        Granted: 1,
        DailyLimitExceeded: 2,
        WeeklyLimitExceeded: 3,
    },
    LanguageDTO: {
        English: 0,
        Polish: 1,
    },
    PageCategoryDTO: {
        HowItWorks: 0,
        Problems: 1,
        Rules: 100,
        Prizes: 1000,
    },
    SetPurchasedTeamPhoto: {
        ErrorCodes: {
            PurchaseDoesNotExist: 1,
            PhotoNotUploaded: 2,
            InvalidPurchaseStatus: 3,
            InvalidPhotoUri: 4,
        },
    },
    TeamPurchaseStatusDTO: {
        New: 0,
        WaitingForPayment: 1,
        Failed: 2,
        Succeeded: 3,
    },
};

export type ClientParams = {
    "createTeamPurchase": Api.CreateTeamPurchase,
    "isPurchaseCompleted": Api.IsPurchaseCompleted,
    "purchaseById": Api.PurchaseById,
    "purchasedTeamPhotoUploadLink": Api.PurchasedTeamPhotoUploadLink,
    "setPurchasedTeamPhoto": Api.SetPurchasedTeamPhoto,
    "teamPrice": Api.TeamPrice,
};

export default function (cqrsClient: CQRS): ClientType<ClientParams> {
    return {
        createTeamPurchase: cqrsClient.executeCommand.bind(cqrsClient, "Activy.Contests.Contracts.Web.Premium.CreateTeamPurchase"),
        isPurchaseCompleted: cqrsClient.executeQuery.bind(cqrsClient, "Activy.Contests.Contracts.Web.Premium.IsPurchaseCompleted"),
        purchaseById: cqrsClient.executeQuery.bind(cqrsClient, "Activy.Contests.Contracts.Web.Premium.PurchaseById"),
        purchasedTeamPhotoUploadLink: cqrsClient.executeQuery.bind(cqrsClient, "Activy.Contests.Contracts.Web.Premium.PurchasedTeamPhotoUploadLink"),
        setPurchasedTeamPhoto: cqrsClient.executeCommand.bind(cqrsClient, "Activy.Contests.Contracts.Web.Premium.SetPurchasedTeamPhoto"),
        teamPrice: cqrsClient.executeQuery.bind(cqrsClient, "Activy.Contests.Contracts.Web.Premium.TeamPrice"),
    };
}
