import React from "react";
import styled from "styled-components";
import RotateLoader from "react-spinners/RotateLoader";
import useTheme from "hooks/use-theme";

export const SplashScreen: React.FunctionComponent = () => {
    const theme = useTheme();

    return (
        <FormContent>
            <LoaderContainer>
                <RotateLoader color={theme.palette.orange} />
            </LoaderContainer>
        </FormContent>
    );
};

const LoaderContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const FormContent = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 10002;
    background: rgba(100, 100, 100, 0.15);
`;
