import color from "color";
import Layout from "components/layout";
import SEO from "components/seo";
import { Form, Formik } from "formik";
import background from "images/demo/background.svg";
import React, { ReactNode, useContext, useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { desktop, mobile } from "styles/media-queries";
import shadowedButton from "styles/shadowed-button";
import themeTransition from "styles/theme-transition";
import { contentCss, rootCss } from "styles/utils";
import * as yup from "yup";
import { ContentContext } from "hooks/use-content";
import api from "../services/api";
import { Router, navigate } from "@reach/router";
import { supplant } from "utils/supplant";
import { generate } from "uuidjs";
import { Label, InputContainer, Input, ValidationMark, Root as FieldRoot } from "components/field";
import { ImagesLoader } from "components/images-loader";
import { SplashScreen } from "components/splash-screen";
import { ErrorMessage } from "components/error-message";

export const Root = styled.section`
    ${rootCss};
    background-color: white;
    padding-top: var(--header-height);
`;

export const ImageWrapper = styled.div`
    flex: 1 1 0;
    position: relative;

    @media ${desktop.smallerThanThis().toString()} {
        display: none;
    }
`;

export const Image = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    width: 50vw;
    background-image: url(${background});
    background-size: cover;
    background-position: right bottom;
`;

export const Content = styled.div`
    ${contentCss};
    display: flex;
`;

export const FormContent = styled.div`
    position: relative;

    padding-top: 4rem;

    @media ${desktop.andUp().toString()} {
        padding-left: 7rem;
        width: 550px;
    }
`;

const Heading = styled.div`
    font-size: 24px;
`;

const ButtonContainer = styled.div`
    margin-top: 3rem;
`;

type FieldProps = {
    label?: ReactNode;
    placeholder?: ReactNode;
    name: string;
    error?: string;
    touched?: boolean;
} & PropsOf<"input">;
const Field = (() => {
    const Field = ({ label, name, placeholder, onChange, onBlur, error, touched, ref, ...inputProps }: FieldProps) => {
        const valid = !error;
        return (
            <FieldRoot>
                <Label>{label}</Label>
                <InputContainer valid={valid}>
                    <Input
                        valid={valid || !touched}
                        placeholder={placeholder}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        {...inputProps}
                    />
                    <ValidationMark valid={valid} show={!!touched}>
                        {/* {error && <ValidationBubble>{error}</ValidationBubble>} */}
                    </ValidationMark>
                </InputContainer>
            </FieldRoot>
        );
    };
    return Field;
})();

const Button = styled.button.attrs({ type: "submit" })`
    ${p => shadowedButton({ color: p.theme.palette.blue })};
    border: none;
    padding: 1rem 1.5rem;
    color: white;
    width: 100%;
    margin-top: 8px;
    cursor: pointer;
    transition: all ${themeTransition()};
    :hover {
        background-color: ${p =>
            color(p.theme.palette.blue)
                .darken(0.2)
                .toString()};
    }
`;

const Row = styled.div`
    width: 100%;
    justify-content: space-between;
    display: flex;

    @media ${mobile.andDown().toString()} {
        flex-direction: column;
    }
`;

const LogoUploadRow = styled(Row)`
    align-items: flex-end;

    @media ${mobile.andDown().toString()} {
        align-items: initial;
    }
`;

const RowField = styled.div`
    flex: 1 1 0;
    margin-right: 16px;

    :last-of-type {
        margin-right: 0;
    }

    @media ${mobile.andDown().toString()} {
        margin-right: 0;
    }
`;

const Section = styled.div`
    margin-bottom: 45px;
`;

const EmphasizedText = styled.span`
    color: #3970ff;
`;

const StyledImagesLoader = styled(ImagesLoader)`
    margin-bottom: 0.5rem;

    @media ${mobile.andDown().toString()} {
        margin-top: 16px;
    }
`;

type DemoFormFields = {
    name: string;
    email: string;
    companyName: string;
    companyAddress: string;
    zipCode: string;
    vatNumber: string;
    teamName: string;
    teamLogo: string;
};

const initialValues: DemoFormFields = {
    name: "",
    email: "",
    companyName: "",
    companyAddress: "",
    teamName: "",
    vatNumber: "",
    zipCode: "",
    teamLogo: "",
};

const validationSchema = yup.object<DemoFormFields>({
    companyAddress: yup.string(),
    companyName: yup.string().required(),
    email: yup
        .string()
        .email()
        .required(),
    name: yup.string().required(),
    vatNumber: yup.string(),
    teamName: yup.string().required(),
    zipCode: yup.string(),
    teamLogo: yup.string(),
});

type LogoFile = {
    name: string;
    file: ArrayBuffer;
};

function FormSection(props: any & { code: string }) {
    const content = useContext(ContentContext).purchase;

    const [percentageDiscount, setPercentageDiscount] = useState<number | undefined>();
    const [logoFile, setLogoFile] = useState<LogoFile | undefined>();

    const [isLoading, setIsLoading] = useState(false);
    const [validationError, setValidationError] = useState<string | undefined>();

    useEffect(() => {
        const fetchTeamPrice = async () => {
            const contestId = process.env.CONTEST_ID || "";

            if (!props.code) {
                return;
            }

            setIsLoading(true);

            const result = await api.teamPrice({
                Code: props.code as string,
                ContestId: contestId,
            });

            if (result.isSuccess) {
                const defaultPrice = result.result.DefaultPrice;
                const promoPrice = result.result.PromoPrice;

                if (defaultPrice && promoPrice) {
                    const discount =
                        ((defaultPrice.AmountInSmallestUnit +
                            (defaultPrice.AmountInSmallestUnit - promoPrice.AmountInSmallestUnit)) /
                            defaultPrice.AmountInSmallestUnit) *
                            100 -
                        100;

                    setPercentageDiscount(Math.round(discount));
                }

                setIsLoading(false);
            }
        };

        fetchTeamPrice();
    }, [props.code]);

    const uploadLogoToBlobStorage = useCallback((sas: string, file: ArrayBuffer) => {
        const headers = new Headers();

        headers.append("x-ms-blob-type", "BlockBlob");

        const request: RequestInit = {
            method: "PUT",
            headers: headers,
            body: file,
        };

        return fetch(sas, request);
    }, []);

    const uploadTeamLogo = useCallback(
        async (purchaseId: string, fileName: string, file: ArrayBuffer) => {
            const fileExtension = fileName.split(".").pop();

            if (!fileExtension) {
                throw "File has no extension";
            }

            const uploadLinkResult = await api.purchasedTeamPhotoUploadLink({
                PurchaseId: purchaseId,
                Extension: fileExtension,
            });

            if (uploadLinkResult.isSuccess) {
                const uploadLink = uploadLinkResult.result.Uri;

                const uploadResponse = await uploadLogoToBlobStorage(uploadLink, file);

                if (uploadResponse.status === 201) {
                    const setTeamPhotoResult = await api.setPurchasedTeamPhoto({
                        PurchaseId: purchaseId,
                        PhotoUri: encodeURI(uploadLink),
                    });

                    if (setTeamPhotoResult.isSuccess && setTeamPhotoResult.result.WasSuccessful) {
                        return true;
                    }
                }
            }

            return false;
        },
        [uploadLogoToBlobStorage],
    );

    const navigateToPayment = useCallback((purchaseId: string) => {
        navigate(`/payment/${purchaseId}`);
    }, []);

    const onSubmit = useCallback(
        async (values: DemoFormFields) => {
            setIsLoading(true);

            const purchaseId = generate();
            const contestId = process.env.CONTEST_ID || "";

            const response = await api.createTeamPurchase({
                Code: props.code as string,
                ContestId: contestId,
                Email: values.email,
                Name: values.name,
                InvoiceInformation: {
                    Address: values.companyAddress,
                    CompanyName: values.companyName,
                    VATNumber: values.vatNumber,
                    ZipCode: values.zipCode,
                },
                PurchaseId: purchaseId,
                TeamName: values.teamName,
            });

            if (response.isSuccess && response.result.WasSuccessful) {
                if (logoFile) {
                    const isLogoUploadSuccessful = await uploadTeamLogo(purchaseId, logoFile.name, logoFile.file);

                    if (!isLogoUploadSuccessful) {
                        setValidationError(content.generalError);
                        return;
                    }
                }

                setIsLoading(false);
                navigateToPayment(purchaseId);
            } else {
                setIsLoading(false);
                setValidationError(content.generalError);
            }
        },
        [props.code, logoFile, navigateToPayment, uploadTeamLogo, content],
    );

    return (
        <FormContent>
            {isLoading && <SplashScreen />}
            <Formik<DemoFormFields>
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ touched, errors, handleBlur, handleChange, values }) => (
                    <Form>
                        <Section>
                            <Heading>
                                {percentageDiscount ? (
                                    <>
                                        {content.sections.personal.header.promoPrize.firstPart}
                                        <EmphasizedText>
                                            {supplant(content.sections.personal.header.promoPrize.discountPart, {
                                                discount: percentageDiscount,
                                            })}
                                        </EmphasizedText>
                                    </>
                                ) : (
                                    <>{content.sections.personal.header.defaultPrize}</>
                                )}
                            </Heading>
                            <Row>
                                <RowField>
                                    <Field
                                        name="name"
                                        label={content.sections.personal.fields.name.label}
                                        placeholder={content.sections.personal.fields.name.placeholder}
                                        error={errors.name}
                                        touched={touched.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                </RowField>
                                <RowField>
                                    <Field
                                        name="email"
                                        label={content.sections.personal.fields.email.label}
                                        placeholder={content.sections.personal.fields.email.placeholder}
                                        error={errors.email}
                                        touched={touched.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                </RowField>
                            </Row>
                        </Section>
                        <Section>
                            <Heading>{content.sections.billing.header}</Heading>
                            <Field
                                name="companyName"
                                label={content.sections.billing.fields.companyName.label}
                                placeholder={content.sections.billing.fields.companyName.placeholder}
                                error={errors.companyName}
                                touched={touched.companyName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.companyName}
                            />
                            <Field
                                name="companyAddress"
                                label={content.sections.billing.fields.companyAddress.label}
                                placeholder={content.sections.billing.fields.companyAddress.placeholder}
                                error={errors.companyAddress}
                                touched={touched.companyAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.companyAddress}
                            />
                            <Row>
                                <RowField>
                                    <Field
                                        name="zipCode"
                                        label={content.sections.billing.fields.zipCode.label}
                                        placeholder={content.sections.billing.fields.zipCode.placeholder}
                                        error={errors.zipCode}
                                        touched={touched.zipCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.zipCode}
                                    />
                                </RowField>
                                <RowField>
                                    <Field
                                        name="vatNumber"
                                        label={content.sections.billing.fields.vatNumber.label}
                                        placeholder={content.sections.billing.fields.vatNumber.placeholder}
                                        error={errors.vatNumber}
                                        touched={touched.vatNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.vatNumber}
                                    />
                                </RowField>
                            </Row>
                        </Section>
                        <Section>
                            <Heading>{content.sections.team.header}</Heading>
                            <Field
                                name="teamName"
                                label={content.sections.team.fields.teamName.label}
                                placeholder={content.sections.team.fields.teamName.placeholder}
                                error={errors.teamName}
                                touched={touched.teamName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.teamName}
                            />
                            <LogoUploadRow>
                                <RowField>
                                    <Field
                                        name="teamLogo"
                                        label={content.sections.team.fields.teamLogo.label}
                                        placeholder={content.sections.team.fields.teamLogo.placeholder}
                                        error={errors.teamLogo}
                                        touched={touched.teamLogo}
                                        onBlur={handleBlur}
                                        value={logoFile ? logoFile.name : ""}
                                        disabled
                                    />
                                </RowField>
                                <RowField>
                                    <StyledImagesLoader
                                        onChange={(fileName, file) => {
                                            setLogoFile({
                                                file: file,
                                                name: fileName,
                                            });
                                        }}
                                        label={content.sections.team.uploadLogoButtonLabel}
                                    />
                                </RowField>
                            </LogoUploadRow>
                        </Section>
                        <Heading>{content.sections.footer.header}</Heading>
                        <ButtonContainer>
                            {validationError && <ErrorMessage>{validationError}</ErrorMessage>}
                            <Button>{content.sections.footer.checkoutButtonLabel}</Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </FormContent>
    );
}

export const FormContainer = styled.div`
    max-width: 100%;
    flex: 1 1 0;
`;

export default function Purchase() {
    return (
        <Layout>
            <SEO title="Demo" />
            <Root>
                <Content>
                    <ImageWrapper>
                        <Image />
                    </ImageWrapper>
                    <FormContainer>
                        <Router>
                            <FormSection path="/promo/:code" />
                            <FormSection path="/purchase" />
                        </Router>
                    </FormContainer>
                </Content>
            </Root>
        </Layout>
    );
}
