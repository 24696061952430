import styled from "styled-components";
import { padding } from "styles/utils";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import invalid from "images/demo/invalid.svg";
import valid from "images/demo/valid.svg";

export const Root = styled.div`
    ${padding.y("0.5rem")};
`;

export const Label = styled.label`
    text-transform: uppercase;
    color: ${themeColor("fontSemiLight")};
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    line-height: 2;
    letter-spacing: 1px;
`;

export const InputContainer = styled.div<{ valid?: boolean }>`
    display: flex;
    border-radius: 10px;
    background-color: ${themeColor("backgroundLight")};
    border: 1px solid ${p => (p.valid ? p.theme.palette.backgroundLight : "#ff244b")};
    transition: all ${themeTransition()};
    padding-top: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.75rem;
`;
export const Input = styled.input<{ valid?: boolean }>`
    flex: 1 1 0;
    color: ${p => (p.valid ? "inherit" : "#ff244b")};
    background: none;
    border: none;
    font-size: 0.875rem;
    line-height: 1rem;
    width: 100%;

    ::placeholder {
        color: ${themeColor("fontSemiDark")};
    }
`;

export const ValidationBubble = styled.div`
    border-radius: 10px;
    background-color: #ff244b;
    opacity: 0;
    transition: opacity ${themeTransition()};
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    color: white;
    padding: 1rem;
`;

export const ValidationMark = styled.div<{ valid?: boolean; show: boolean }>`
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    background-image: url(${p => (p.valid ? valid : invalid)});
    background-size: cover;
    position: relative;

    &:hover > ${ValidationBubble} {
        opacity: 1;
    }

    visibility: ${p => (p.show ? "visible" : "hidden")};
`;
