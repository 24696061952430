import styled from "styled-components";
import Markdown from "./markdown";

export const ErrorMessage = styled(Markdown)`
    color: red;

    p {
        margin: 6px 0;
    }
`;
